import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import { post } from '@/assets/js/http'
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: "",
        avatar: "",
        username: "",
        buttons: [],
        menu: [],
        type: 1,
        redirectUrl:'http://117.176.180.60:9000',
        // redirectUrl:'http://117.176.180.60:9000?redirect=http://localhost:8080/web/login'
    },
    mutations: {
        login(state, payload) {
            state.token = payload.token;
        },
        async logout(state) {
            if(!state.token){
                this.$message.error('没有Token 即将跳转登录')
                setTimeout(() => {
                    window.top.location.href = state.redirectUrl
                }, 1000);
                return
            }
            let logoutRes = await post({
                url: "/admin/index/logout",
                data: {},
                loading: true
            });
            state.menu = []
            state.token = "";
            state.menu = []
            this.$message.success('退出成功')
            setTimeout(() => {
                window.top.location.href = state.redirectUrl
            }, 1000);
        },
        setAdminInfo(state, payload) {
            state.avatar = payload.avatar;
            state.username = payload.username;
        },
        setButtons(state, payload) {
            state.buttons = payload.filter(item => item.name != 'hidden');
        },
        SET_MENU(state, payload) {
            state.menu = payload
        },
        setType(state, type) {
            state.type = type
        },
    },
    actions: {
        setMenu(ctx, payload) {
            ctx.commit('SET_MENU', payload)
        },
        setType(ctx, type) {
            ctx.commit('setType', type)
        },
        setAdminInfo(ctx, userInfo) {
            ctx.commit('setAdminInfo', userInfo)
        },
        setToken(ctx,token){
            ctx.commit('login',{token})
        }
    },
    getters: {
        question_cate: () => {
            return {
                0: '其他',
                1: '融资需求',
                2: '要素保障',
                3: '人才引留',
                4: '环保安全',
                5: '办事审批',
                6: '税收社保',
                7: '政策兑现',
                8: '政策咨询'
            }
        },
        appeal_status: () => {
            return {
                0: '未处理',
                1: '处理中',
                2: '无法处理',
                3: '已办结'
            }
        }
    },
    modules: {

    },
    plugins: [
        createPersistedState()
    ]
})
