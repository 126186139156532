<template>
	<router-view></router-view>
</template>
<script>
import { post } from "@/assets/js/http.js"
export default {
	data() {
		return {}
	},
	created() {
		window.location.href = "http://117.176.180.60:9000"
	},
	mounted() {
		//验证是否登录
		this.$nextTick(async () => {
			this.setType()
			await this.regLogin()
		})
	},
	watch: {
		$route: function () {
			this.setType()
		},
	},
	methods: {
		async regLogin() {
			// 判断url是否有ticket和type
			let param = this.getUrlParam()
			if (param.type && param.ticket && window.location.href.indexOf("/web/login") == -1) {
				window.location.href = "/web/login?ticket=" + param.ticket + "&type=" + param.type
				return
			}
			if (!this.$store.state.token && window.location.href.indexOf("ticket") == -1) {
				console.log("! ticket !token")
				this.$confirm("您尚未登录?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				})
					.then(() => {
						window.location.href = this.$store.state.redirectUrl
					})
					.catch(() => {
						window.location.href = this.$store.state.redirectUrl
					})
				return
			}
		},
		setType() {
			if (this.$store.state.type && this.$route.query.ticket) {
				this.$store.dispatch("setType", this.$store.state.type)
			}
		},
		getUrlParam() {
			var url = window.location.href
			var theRequest = new Object()
			if (url.indexOf("?") != -1) {
				var str = url.split("?")[1]
				var strs = str.split("&")
				for (var i = 0; i < strs.length; i++) {
					theRequest[strs[i].split("=")[0]] = decodeURIComponent(strs[i].split("=")[1])
				}
			}
			return theRequest
		},
	},
}
</script>
<style src="@/assets/css/element-m.css"></style>
<style src="@/assets/css/app.css"></style>
<style>
.el-message {
	z-index: 3002 !important;
}

.el-page-header__content {
	font-size: 15px;
}
</style>
