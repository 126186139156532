<template>
    <div class="login-page">
        <!-- <div class="login-content">
            <div class="login_imgbox">
                <div class="login_img">
                    <img src="../assets/image/loginbg.png" style="width:100%;max-width: 700px;" alt="">
                </div>
            </div>
            <div>
                <div class="login-title" v-if="$route.query.type=='company'">邻水县“千名干部访千企”服务企业平台</div>
                <div class="login-title" v-if="$route.query.type=='masses'">“1+N”服务群众管理后台</div>
                <div class="login-container">
                    <h2 class="login-t">
                        <span>用户登录</span>
                    </h2>
                    <div class="login-field">
                        <img src="@/assets/image/login-i-1.png" style="margin-left: 20px" alt="">
                        <input v-model="username" class="login-field-value" placeholder="请输入用户名"
                               autocomplete="new-password"
                               type="text"/>
                    </div>
                    <div class="login-field">
                        <img src="@/assets/image/login-i-2.png" style="margin-left: 20px" alt="">
                        <input v-model="password" class="login-field-value" placeholder="请输入密码"
                               autocomplete="new-password"
                               type="password"/>
                    </div>
                    <div class="login-button" @click="onLogin">登录</div>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
import { post } from "@/assets/js/http.js";

export default {
    name: "view-login",
    data() {
        return {
            save: false,
            username: "",
            password: "",
        };
    },
    created() {
        this.$nextTick(async()=>{
            await this.$login()
        })
    },
    mounted() {
        if (this.save) {
            this.username = localStorage.getItem("username");
            this.password = localStorage.getItem("password");
        } else {
            this.username = "";
            this.password = "";
        }
    },
    methods: {
        async onLogin() {
            if (this.username && this.password) {
                localStorage.setItem("save", this.save);
                if (this.save) {
                    localStorage.setItem("username", this.username);
                    localStorage.setItem("password", this.password);
                } else {
                    localStorage.removeItem("username");
                    localStorage.removeItem("password");
                }
                const data = await post({
                    url: "/admin/index/login",
                    loading: true,
                    successTip: true,
                    data: {
                        username: this.username,
                        password: this.password
                    }
                });
                localStorage.setItem("role", data.role);
                this.$store.commit("login", data.userinfo);
                const res = await post({
                    url: "/admin/index/index",
                    loading: true,
                    data:{
                        type: this.$store.state.type
                    }
                });
                this.$store.dispatch("setAdminInfo", res.adminInfo);
                this.$store.dispatch('setMenu', res.menus)
                window.location.href = '/'
            } else {
                this.$message.error("用户名和密码不能为空");
            }
        },
        tologon() {
            this.$router.push("/logon");
            // this.$router.push("/supplier");
        }
    }
};
</script>
<style>
.login-content {
    display: flex;
    max-width: 1700px;
    width: 100%;
    margin: 0 auto;
    justify-content: space-between;
    padding: 100px;
    box-sizing: border-box;
    height: 100%;
    align-items: center;
}

.login-container {
    background: white;
    width: 590px;
    height: 540px;
    padding: 40px 84px;
    border-radius: 10px;
}

.login-t {
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
}

.login-t span {
    height: 35px;
    font-size: 30px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #474B51;
    text-align: center;
    position: relative;
}

.login-t span::after {
    content: '';
    display: block;
    height: 10px;
    width: 140px;
    background: rgba(0, 0, 255, .1);
    bottom: -4px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
</style>